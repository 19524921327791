<template>
  <div class="card">
    <h1 class="card-header">Link an existing contact</h1>
    <div class="card-body">
      <transition-group
        name="fade"
        tag="div"
        class="row row-cols-lg-3 row-cols-md-2 row-cols-1"
        v-on:should-close="shouldClose"
      >
        <div v-for="contact in existing" :key="contact.id" class="col my-2">
          <contact-card :contact="contact" v-on:should-close="shouldClose">
            <template v-slot:footer="shouldClose">
              <information-card-contact-link
                :contact="contact"
                :information-card-id="informationCardId"
              />
            </template>
          </contact-card>
        </div>
      </transition-group>
    </div>
    <div class="card-footer">
      <cs-button
        @handle-click.prevent="shouldClose($event)"
        iconLeft="cancel"
        type="primary"
        class="btn-block"
      >
        Close
      </cs-button>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import ContactCard from './ContactCard'
import InformationCardContactLink from './InformationCardContactLink'
export default {
  name: 'ContactSelect',
  components: { ContactCard, InformationCardContactLink },
  props: {
    informationCardId: {
      type: [String, Number],
      required: true
    },
    informationCardContacts: {
      type: Array,
      required: true
    },
    contacts: {
      type: Array,
      required: true
    }
  },
  watch: {
    existing (existingContacts) {
      if (existingContacts.length === 0) {
        this.$emit('should-close')
      }
    }
  },
  methods: {
    shouldClose (event) {
      if (!isEmpty(event)) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
      }
      this.$emit('should-close', event)
    }
  },
  computed: {
    existing () {
      var indexes = this.informationCardContacts.map(function (
        informationCardContact
      ) {
        return informationCardContact.contact_id
      })
      var filteredContacts = this.contacts.filter(function (contact) {
        // executed for each person
        const found = indexes.indexOf(contact.id)
        return found === -1
      })
      return filteredContacts
    }
  }
}
</script>

<style scoped></style>

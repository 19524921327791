<template>
  <div class="card border-info">
    <h3 class="card-header bg-info-light">
      {{ information_card_contact.name }}
    </h3>
    <div class="card-body">
      <div class="row">
        <div class="col col-md-6">
          <p class="lead">{{ information_card_contact.relationship.title }}</p>
        </div>
        <div class="col col-md-6">
          <p><strong>Permisions</strong></p>
          <ul class="list-unstyled">
            <li v-if="information_card_contact.can_pickup">Can pickup</li>
            <li v-else>Can not pickup</li>
            <li v-if="information_card_contact.is_custodial">Is custodial</li>
            <li v-else>Not custodial</li>
            <li v-if="information_card_contact.is_resident">Is resident</li>
            <li v-else>Not resident</li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-6">
          <strong>Phones</strong>
          <ul class="list-unstyled">
            <li v-for="phone in phones" :key="phone.id">
              {{ phone(phone.phone_number) }}
              <span class="badge">{{ phone.type }}</span>
            </li>
          </ul>
        </div>
        <div class="col col-md-6">
          <strong>Emails</strong>
          <ul class="list-unstyled">
            <li v-for="email in emails" :key="email.id">
              {{ email.address }}
              <span class="badge">{{ email.type }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <strong>Addresses</strong>
          <ul class="list-unstyled">
            <li v-for="address in addresses" :key="address.id">
              {{ address.address1 }} <br />{{ address.address1 }}
              {{ address.city }}, {{ address.state }} {{ address.zip }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <cs-button
        @handle-click="shouldEdit($event)"
        iconLeft="edit"
        type="warning"
      >
        Edit
      </cs-button>
      <cs-button
        @handle-click="shouldClose($event)"
        iconLeft="cancel"
        type="primary"
      >
        Close
      </cs-button>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash/core'
import { phone } from '@/utilities/stringFormatters'
import InformationCardContact from '@/models/InformationCardContact'

export default {
  name: 'ContactView',
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  methods: {
    phone,
    shouldClose (event) {
      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
      this.$emit('shouldClose', event)
    },
    shouldEdit (event) {
      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
      this.$emit('shouldEdit', event)
    }
  },
  computed: {
    information_card_contact () {
      let id = this.id
      if (isEmpty(id)) {
        id = ''
      }
      if (id !== '') {
        return InformationCardContact.query()
          .whereId(id)
          .orderBy('sort')
          .with('contact.*')
          .with('relationship')
          .first()
      }
      return null
    },
    contact: function () {
      return this.information_card_contact.contact
    },
    phones: function () {
      return this.contact.phones
    },
    emails: function () {
      return this.contact.emails
    },
    addresses: function () {
      return this.contact.addresses
    }
  }
}
</script>

<style scoped></style>

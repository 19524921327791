<template>
  <div class="card shadow p-1">
    <img
      :src="signature.path"
      class="card-img-top"
      :alt="'signature created on: ' + signature.created"
    />
    <div class="card-footer">
      <em class="text-muted">{{ dateString(signature.created) }}</em>
    </div>
  </div>
</template>

<script>
import { dateString } from '@/utilities/dateFormatters'

export default {
  name: 'SignatureView',
  props: {
    signature: {
      type: Object,
      required: true
    }
  },
  methods: {
    dateString
  }
}
</script>

<style scoped></style>

<template>
  <div>
    <transition mode="out-in">
      <div v-if="information_card_contacts.length === 0">
        <h3>
          You have no emergency contacts yet.
        </h3>
        <p class="lead">
          Registering for programs requires at least two
        </p>
        <div class="row justify-content-around">
          <cs-button
            v-if="editable"
            @handle-click.prevent="addContact()"
            type="warning"
            iconLeft="user-plus"
            >Add a contact
          </cs-button>
          <cs-button
            v-wait:hidden="'members.contacts.load'"
            @handle-click.prevent="selectContact()"
            type="warning"
            iconLeft="user-plus"
            v-if="existingCount !== 0"
          >
            {{
              existingCount > 0
                ? "Select from previous contacts"
                : "No unused contacts"
            }}
          </cs-button>

          <cs-loading v-wait:visible="'members.contacts.load'" theme="success">
            Loading Previous Contacts
          </cs-loading>
        </div>
      </div>
      <div v-else class="table-responsive">
        <table class="table table-striped table-bordered table-hover">
          <thead class="thead-light">
            <tr>
              <th v-if="editable">Sort</th>
              <th>Name</th>
              <th>Relationship</th>
              <th>Pickup</th>
              <th v-if="editable" class="action">Actions</th>
            </tr>
          </thead>

          <tbody key="contacts-table" is="transition-group" name="table-row">
            <p
              v-if="information_card_contacts.length === 1"
              class="lead text-danger"
              key="contacts-warning"
            >
              Registering for programs requires at least two
            </p>
            <tr
              v-for="information_contact in information_card_contacts"
              :key="information_contact.id"
            >
              <td v-if="editable">
                <span class="btn-group">
                  <cs-button
                    v-if="information_contact.sort > 1"
                    @handle-click.prevent="up(information_contact.id)"
                    type="primary"
                    iconLeft="up"
                    size="sm"
                  />
                  <cs-button
                    v-if="
                      information_contact.sort <
                        information_card_contacts.length
                    "
                    @handle-click.prevent="down(information_contact.id)"
                    type="primary"
                    iconLeft="down"
                    size="sm"
                  />
                </span>
              </td>
              <td>
                {{ information_contact.contact.name }} <br />
                <span class="text-muted small">{{
                  toList(information_contact.contact.phoneArray)
                }}</span>
              </td>
              <td>{{ information_contact.relationship.title }}</td>
              <td>{{ niceBoolean(information_contact.can_pickup) }}</td>
              <td v-if="editable" class="actions">
                <span class="btn-group">
                  <cs-button
                    @handle-click.prevent="view(information_contact.id)"
                    type="primary"
                    iconLeft="view"
                    size="sm"
                  >
                    View
                  </cs-button>
                  <cs-button
                    @handle-click.prevent="edit(information_contact.id)"
                    type="warning"
                    iconLeft="edit"
                    size="sm"
                  >
                    Edit
                  </cs-button>
                  <cs-button
                    @handle-click.prevent="unlink(information_contact.id)"
                    type="danger"
                    iconLeft="delete"
                    size="sm"
                    :loading="$wait.is('unlink_' + information_contact.id)"
                    :disabled="$wait.is('unlink_' + information_contact.id)"
                  >
                    Unlink
                  </cs-button>
                </span>
              </td>
            </tr>
          </tbody>

          <tfoot v-if="editable">
            <tr>
              <td colspan="5">
                <div class="row justify-content-around">
                  <cs-button
                    @handle-click.prevent="addContact()"
                    type="warning"
                    iconLeft="user-plus"
                  >
                    Add a contact
                  </cs-button>

                  <cs-button
                    v-wait:hidden="'members.contacts.load'"
                    @handle-click.prevent="selectContact()"
                    type="warning"
                    iconLeft="user-plus"
                    :disabled="existingCount === 0"
                  >
                    {{
                      existingCount > 0
                        ? "Select from previous contacts"
                        : "No unused contacts"
                    }}
                  </cs-button>

                  <cs-loading
                    v-wait:visible="'members.contacts.load'"
                    theme="success"
                  >
                    Loading Previous Contacts
                  </cs-loading>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </transition>
    <modal name="edit-contact" height="auto" :scrollable="true" width="80%">
      <contact-edit
        :id="contact_id"
        :information_card_id="information_card_id"
        @didSave.prevent="hide('edit-contact')"
        @shouldClose.prevent="hide('edit-contact')"
      />
    </modal>
    <modal name="view-contact" height="auto" :scrollable="true" width="80%">
      <contact-view
        :id="contact_id"
        :information_card_id="information_card_id"
        @shouldEdit.prevent="shouldEdit('view-contact')"
        @shouldClose.prevent="hide('view-contact')"
      />
    </modal>
    <modal name="link-contact" height="auto" :scrollable="true" width="80%">
      <contact-select
        :information-card-id="information_card.id"
        :information-card-contacts="information_card_contacts"
        :contacts="memberContacts"
        @should-close="hide('link-contact')"
      />
    </modal>
  </div>
</template>

<script>
import { API } from '@/utilities/http-common'
import { InitialState } from '@/utilities/cs-utilities'
import isEmpty from 'lodash/isEmpty'
import InformationCardContact from '@/models/InformationCardContact'
import Contact from '@/models/Contact'
import ContactView from './ContactView'
import ContactEdit from './ContactEdit'
import ContactSelect from './ContactSelect'
import { toList, niceBoolean } from '@/utilities/stringFormatters'

export default {
  name: 'Contacts',
  components: { ContactEdit, ContactView, ContactSelect },
  props: {
    information_card: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    member_id: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      contact_id: ''
    }
  },
  computed: {
    information_card_id () {
      return this.information_card.id
    },
    information_card_contacts () {
      var ics = InformationCardContact.query()
        .where('information_card_id', this.information_card_id)
        .with('contact.*')
        .with('relationship.*')
        .orderBy('sort')
        .get()
      return ics
    },
    memberContacts () {
      const memberId = this.member_id.toString()
      return Contact.query()
        .where('user_id', memberId)
        .with(['phones', 'addresses', 'emails'])
        .get()
    },
    contactsCount () {
      if (isEmpty(this.memberContacts)) {
        return 0
      }
      return this.memberContacts.length
    },
    existingCount () {
      var indexes = this.information_card_contacts.map(function (
        informationCardContact
      ) {
        return informationCardContact.contact_id
      })
      if (this.memberContacts.length !== 0) {
        var filteredContacts = this.memberContacts.filter(function (contact) {
          // executed for each person
          const found = indexes.indexOf(contact.id)
          return found === -1
        })
        return filteredContacts.length
      }
      return 0
    }
  },
  mounted () {
    const params = {
      params: {
        member_id: this.information_card.member_id
      }
    }
    if (
      typeof InitialState() !== 'undefined' &&
      typeof InitialState().contacts !== 'undefined'
    ) {
      const memberContacts = InitialState().contacts
      memberContacts.forEach(contact => {
        Contact.insertOrUpdate({ data: contact })
      })
    } else {
      this.$wait.start('members.contacts.load')
      API.get('/members/contacts.json', params)
        .then(result => {
          if (result.status === 200) {
            if (
              typeof result.data.contacts !== 'undefined' &&
              result.data.eligible !== null
            ) {
              result.data.contacts.forEach(contact => {
                Contact.insertOrUpdate({ data: contact })
              })
            }
          }
          this.$wait.end('members.contacts.load')
        })
        .catch(() => {
          this.$wait.end('members.contacts.load')
        })
    }
  },
  methods: {
    toList,
    niceBoolean,
    addContact () {
      this.contact_id = ''
      this.show('edit-contact')
    },
    selectContact () {
      this.show('link-contact')
    },
    view (id) {
      this.contact_id = id
      this.show('view-contact')
    },
    edit (id) {
      this.contact_id = id
      this.show('edit-contact')
    },
    unlink (id) {
      const params = {
        id: id
      }
      this.$wait.start('unlink_' + id)
      API.delete('/information_cards_contacts/delete', { params: params })
        .then(response => {
          const data = response.data
          if (data.result.isError === false) {
            this.$notify({
              group: 'default',
              type: 'success',
              title: 'Removed',
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
            this.$wait.end('unlink_' + id)
            InformationCardContact.delete(id)
          } else {
            this.$wait.end('unlink_' + id)
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'delete error',
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
            this.$wait.end('unlink_' + id)
          }
        })
        .catch(e => {
          this.$notify({
            group: 'default',
            type: 'error',
            title: 'delete catch',
            text: e.message,
            duration: 2000,
            speed: 1000
          })
        })
    },
    shouldEdit (name) {
      this.$modal.hide(name)
      this.edit(this.contact_id)
    },
    show (name) {
      this.$modal.show(name)
    },
    hide (name) {
      this.$modal.hide(name)
    },
    up (id) {
      this.move(id, 'up')
    },
    down (id) {
      this.move(id, 'down')
    },
    move (id, direction) {
      const data = {
        id: id,
        direction: direction
      }
      API.patch('/information_cards_contacts/sort', data)
        .then(response => {
          const data = response.data
          if (data.result.isError === false) {
            this.$notify({
              group: 'default',
              type: 'success',
              title: 'sort order updated',
              duration: 2000,
              speed: 1000
            })
            const sorted = data.sorted

            sorted.forEach(function (item) {
              InformationCardContact.update({
                where: item.id,
                data: { sort: item.sort }
              })
            })
          } else {
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'sort error',
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
          }
        })
        .catch(e => {
          this.$notify({
            group: 'default',
            type: 'error',
            title: 'sort catch',
            text: e.message,
            duration: 2000,
            speed: 1000
          })
        })
    }
  }
}
</script>

<style scoped>
.table-row-move {
  transition: transform 1s;
}
</style>

<template>
  <div v-if="hasAddress">
    <div class="row">
      <div class="col col-lg-6">{{ address }}</div>
      <div v-if="address !== ''" class="col col-lg-6">{{ address2 }}</div>
    </div>
    <div class="row">
      <div class="col">{{ city }}, {{ state }} {{ zip }}</div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash/core'
export default {
  name: 'AddressView',
  props: {
    address: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    },
    address2: {
      validator: prop => typeof prop === 'string' || prop === null,
      default: ''
    },
    city: {
      validator: prop => typeof prop === 'string' || prop === null,
      default: 'Tucson'
    },
    state: {
      validator: prop => typeof prop === 'string' || prop === null,
      default: 'AZ'
    },
    zip: {
      validator: prop => typeof prop === 'string' || prop === null,
      default: ''
    }
  },
  computed: {
    hasAddress () {
      return !isEmpty(this.address)
    }
  }
}
</script>

<style scoped></style>

<template>
  <form @submit.prevent="save($event)">
    <div class="card">
      <h3 class="card-header">
        {{ title }}
        <small>
          <cs-button
            @handle-click="shouldClose"
            iconLeft="cancel"
            type="danger"
            class="float-right"
            customClass="btn-sm"
            >Close
          </cs-button>
        </small>
      </h3>
      <div class="card-body">
        <div class="row">
          <cs-form-group
            class="col-md-6"
            label="Relationship to Student"
            input_id="relationship"
            :validator="$v.contact.relationship"
          >
            <template v-slot:input>
              <multiselect
                v-model="$v.contact.relationship.$model"
                :options="relationshipOptions"
                :multiple="false"
                placeholder="Type"
                track-by="value"
                label="value"
                name="test"
              >
                >
              </multiselect>
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.contact.relationship.$invalid">
                  Please select a relationship to your student
                </div>
              </transition>
            </template>
          </cs-form-group>
          <cs-form-group
            class="col-md-6"
            label="Permissions"
            input_id="relationship"
          >
            <template v-slot:input>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="can-pickup"
                  v-model="contact.can_pickup"
                />
                <label class="custom-control-label" for="can-pickup"
                  >Can Pickup</label
                >
              </div>

              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is-custodial"
                  v-model="contact.is_custodial"
                />
                <label class="custom-control-label" for="is-custodial"
                  >Is Custodial</label
                >
              </div>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="is-resident"
                  v-model="contact.is_resident"
                />
                <label class="custom-control-label" for="is-resident"
                  >Is Resident</label
                >
              </div>
            </template>
          </cs-form-group>
        </div>
        <div class="row">
          <cs-form-group
            class="col-md-6"
            label="First Name"
            input_id="first_name"
            :validator="$v.contact.first_name"
          >
            <template v-slot:input>
              <input
                type="text"
                class="form-control"
                id="first_name"
                v-model.trim.lazy="$v.contact.first_name.$model"
                :class="{
                  'is-valid': !$v.contact.first_name.$invalid,
                  'is-invalid': $v.contact.first_name.$error
                }"
              />
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.contact.first_name.minLength">
                  First name must have at least
                  {{ $v.contact.first_name.$params.minLength.min }} letters.
                </div>
              </transition>
            </template>
          </cs-form-group>
          <cs-form-group
            class="col-md-6"
            label="Last Name"
            input_id="last_name"
            :validator="$v.contact.last_name"
          >
            <template v-slot:input>
              <input
                type="text"
                class="form-control"
                id="last_name"
                v-model.trim.lazy="$v.contact.last_name.$model"
                :class="{
                  'is-valid': !$v.contact.last_name.$invalid,
                  'is-invalid': $v.contact.last_name.$error
                }"
              />
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.contact.last_name.minLength">
                  Last name must have at least
                  {{ $v.contact.first_name.$params.minLength.min }} letters.
                </div>
              </transition>
            </template>
          </cs-form-group>
        </div>
        <h5 required class="required text-danger">
          Phone<span class="text-danger">*</span>
          <small class="text-muted"
            >At least one phone number is required</small
          >
        </h5>
        <div class="row">
          <div class="col-md-3">Main</div>

          <cs-form-group class="col-md-3" label="" input_id="phone_1_type">
            <template v-slot:input>
              <multiselect
                v-model="contact.phone_1_type"
                :options="phoneTypes"
                :multiple="false"
                track-by="value"
                label="value"
                :allowEmpty="false"
                placeholder="Select type"
              >
              </multiselect>
            </template>
          </cs-form-group>
          <cs-form-group
            class="col-md-3"
            label=""
            input_id="phone_1"
            :validator="$v.contact.phone_1"
          >
            <template v-slot:input>
              <input
                v-model.trim.lazy="$v.contact.phone_1.$model"
                v-facade="'(###) ###-####'"
                placeholder="(###) ###-####"
                type="tel"
                class="form-control"
                id="phone_1"
                :class="{
                  'is-valid': !$v.contact.phone_1.$invalid,
                  'is-invalid': $v.contact.phone_1.$error
                }"
              />
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.contact.phone_1.minLength">
                  Number must have at least
                  {{ $v.contact.phone_1.$params.minLength.min }} letters.
                </div>
              </transition>
            </template>
          </cs-form-group>
        </div>
        <div class="row">
          <div class="col-md-3">Secondary</div>

          <cs-form-group class="col-md-3" label="" input_id="phone_1_type">
            <template v-slot:input>
              <multiselect
                v-model="contact.phone_2_type"
                :options="phoneTypes"
                :multiple="false"
                track-by="value"
                label="value"
                :allowEmpty="false"
                placeholder="Select type"
              >
              </multiselect>
            </template>
          </cs-form-group>
          <cs-form-group
            class="col-md-3"
            label=""
            input_id="phone_2"
            :validator="$v.contact.phone_2"
          >
            <template v-slot:input>
              <input
                  v-facade="'(###) ###-####'"
                v-model.trim.lazy="$v.contact.phone_2.$model"
                placeholder="(###) ###-####"
                type="tel"
                class="form-control"
                id="phone_2"
                :class="{
                  'is-valid': !$v.contact.phone_2.$invalid,
                  'is-invalid': $v.contact.phone_2.$error
                }"
              />
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.contact.phone_2.minLength">
                  Number must have at least
                  {{ $v.contact.phone_2.$params.minLength.min }} letters.
                </div>
              </transition>
            </template>
          </cs-form-group>
        </div>
        <h5>
          Email
          <small class="text-muted">
            (optional) but recommended for people who have permission to pick up
            or are custodial
          </small>
        </h5>
        <div class="row">
          <div class="col-md-3">
            Email <small class="text-muted">(optional)</small>
          </div>

          <cs-form-group class="col-md-3" label="" input_id="email_type">
            <template v-slot:input>
              <multiselect
                v-model="contact.email_type"
                :options="emailTypes"
                :multiple="false"
                track-by="value"
                label="value"
                :allowEmpty="false"
                placeholder="Select type"
              >
              </multiselect>
            </template>
          </cs-form-group>
          <cs-form-group
            class="col-md-3"
            label=""
            input_id="email"
            :validator="$v.contact.email"
          >
            <template v-slot:input>
              <input
                v-model.trim.lazy="$v.contact.email.$model"
                placeholder="you@example.com"
                class="form-control"
                id="email"
                :class="{
                  'is-valid': !$v.contact.email.$invalid,
                  'is-invalid': $v.contact.email.$error
                }"
              />
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.contact.email.minLength">
                  Email must have at least
                  {{ $v.contact.email.$params.minLength.min }} letters.
                </div>
              </transition>
            </template>
          </cs-form-group>
        </div>
        <h5>Address <small class="text-muted">(optional)</small></h5>
        <div class="row">
          <cs-form-group
            class="col"
            label="Address"
            input_id="address"
            :validator="$v.contact.address"
          >
            <template v-slot:input>
              <input
                type="text"
                class="form-control"
                id="address"
                v-model.trim.lazy="$v.contact.address.$model"
                :class="{
                  'is-valid': !$v.contact.address.$invalid,
                  'is-invalid': $v.contact.address.$error
                }"
              />
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.contact.address.minLength">
                  First name must have at least
                  {{ $v.contact.address.$params.minLength.min }} letters.
                </div>
              </transition>
            </template>
          </cs-form-group>
        </div>
        <div class="row">
          <cs-form-group
            class="col-md-4"
            label="City"
            input_id="city"
            :validator="$v.contact.city"
          >
            <template v-slot:input>
              <input
                type="text"
                class="form-control"
                id="city"
                v-model.trim.lazy="$v.contact.city.$model"
                :class="{
                  'is-valid': !$v.contact.city.$invalid,
                  'is-invalid': $v.contact.city.$error
                }"
              />
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.contact.city.minLength">
                  First name must have at least
                  {{ $v.contact.city.$params.minLength.min }} letters.
                </div>
              </transition>
            </template>
          </cs-form-group>

          <cs-form-group
            class="col-md-3"
            label="State"
            input_id="state"
            :validator="$v.contact.state"
          >
            <template v-slot:input>
              <multiselect
                v-model="$v.contact.state.$model"
                :options="states"
                :multiple="false"
                placeholder="Type to search"
                track-by="value"
                label="value"
              >
                <span slot="noResult"
                  >Oops! No elements found. Consider changing the search
                  query.</span
                >
              </multiselect>
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.contact.state.between">
                  You must pick a state
                </div>
              </transition>
            </template>
          </cs-form-group>
          <cs-form-group
            class="col-md-3"
            label="Zip"
            input_id="zip"
            :validator="$v.contact.zip"
          >
            <template v-slot:input>
              <input
                type="text"
                class="form-control"
                id="zip"
                v-model.trim.lazy="$v.contact.zip.$model"
                :class="{
                  'is-valid': !$v.contact.zip.$invalid,
                  'is-invalid': $v.contact.zip.$error
                }"
              />
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.contact.zip.minLength">
                  First name must have at least
                  {{ $v.contact.zip.$params.minLength.min }} letters.
                </div>
              </transition>
            </template>
          </cs-form-group>
        </div>
      </div>
      <div class="card-footer">
        <cs-button
          @handle-click="save($event)"
          iconLeft="save"
          type="primary"
          class="mx-2"
          :disabled="$wait.is('contact.saving')"
          v-wait:disabled="'contact.saving'"
          >Save
        </cs-button>
        <cs-button
          @handle-click="shouldClose($event)"
          iconLeft="cancel"
          type="danger"
        >
          Close
        </cs-button>
        <span v-wait:visible="'contact.saving'">
          <cs-loading theme="success">Saving the contact</cs-loading>
        </span>
      </div>
    </div>
  </form>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { validationMixin } from 'vuelidate'
import { API } from '@/utilities/http-common'
import {
  maxLength,
  minLength,
  required,
  email
} from 'vuelidate/lib/validators'

import { facade } from 'vue-input-facade'
import InformationCardContact from '@/models/InformationCardContact'
import InformationCard from '@/models/InformationCard'
export default {
  name: 'ContactEdit',
  mixins: [validationMixin],
  directives: { facade },
  props: {
    id: {
      type: [Number, String],
      default: null
    },
    information_card_id: {
      type: [Number, String],
      required
    }
  },
  data () {
    return {
      contact: {
        id: null,
        first_name: '',
        last_name: '',
        address: '',
        city: '',
        state: { key: 'AZ', value: 'Arizona' },
        zip: '',
        email: '',
        email_type: { key: 'main', value: 'Main' },
        phone_1: '',
        phone_1_type: { key: 'cell', value: 'Cell' },
        phone_2: '',
        phone_2_type: { key: 'home', value: 'Home' },
        relationship: { key: 5, value: 'Emergency Contact' },
        can_pickup: false,
        is_custodial: false,
        is_resident: false
      }
    }
  },
  created () {
    if (this.id !== '') {
      this.loadFromId()
    }
  },
  watch: {
    id () {
      this.loadFromId()
    }
  },
  computed: {
    information_card_contact_json () {
      const informationCardContact = cloneDeep(this.contact)
      let icSource = null
      if (!isEmpty(this.id) && this.id !== '') {
        informationCardContact.id = this.id
        icSource = this.information_card_contact
      }
      informationCardContact.information_card_id = this.information_card_id
      informationCardContact.member_id = this.member_id
      informationCardContact.student_id = this.student_id
      informationCardContact.relationship_id =
        informationCardContact.relationship.key

      informationCardContact.contact = {
        first_name: informationCardContact.first_name,
        last_name: informationCardContact.last_name,
        user_id: this.member_id,
        addresses: [
          {
            address1: informationCardContact.address,
            city: informationCardContact.city,
            state: !isEmpty(informationCardContact.state)
              ? informationCardContact.state.key
              : '',
            zip: informationCardContact.zip
          }
        ],
        emails: [
          {
            address: informationCardContact.email,
            type: !isEmpty(informationCardContact.email_type)
              ? informationCardContact.email_type.key
              : ''
          }
        ],
        phones: [
          {
            phone_number: informationCardContact.phone_1,
            type: !isEmpty(informationCardContact.phone_1_type)
              ? informationCardContact.phone_1_type.key
              : ''
          },
          {
            phone_number: informationCardContact.phone_2,
            type: !isEmpty(informationCardContact.phone_2_type)
              ? informationCardContact.phone_2_type.key
              : ''
          }
        ]
      }
      if (icSource !== null) {
        if (!isEmpty(icSource.contact)) {
          const contact = icSource.contact
          informationCardContact.contact.id = contact.id
          if (!isEmpty(contact.addresses) && contact.addresses.length > 0) {
            const address = contact.addresses[0]
            informationCardContact.contact.addresses[0].id = address.id
          }
          if (!isEmpty(contact.emails) && contact.emails.length > 0) {
            const email = contact.emails[0]
            informationCardContact.contact.emails[0].id = email.id
          }
          if (!isEmpty(contact.phones) && contact.phones.length > 0) {
            const phone = contact.phones[0]
            informationCardContact.contact.phones[0].id = phone.id
          }
          if (!isEmpty(contact.phones) && contact.phones.length > 1) {
            const phone = contact.phones[1]
            informationCardContact.contact.phones[1].id = phone.id
          }
        }
      }
      delete informationCardContact.relationship
      delete informationCardContact.phone_2_type
      delete informationCardContact.email_type
      delete informationCardContact.state
      delete informationCardContact.phone_1_type
      return informationCardContact
    },
    emailTypes () {
      return [
        { key: 'main', value: 'Main' },
        { key: 'home', value: 'Home', work: 'Work' },
        { key: 'other', value: 'Other' }
      ]
    },
    phoneTypes () {
      return [
        { key: 'home', value: 'Home' },
        { key: 'cell', value: 'Cell' },
        { key: 'work', value: 'Work' },
        { key: 'other', value: 'Other' }
      ]
    },
    relationshipOptions () {
      return [
        { key: 1, value: 'Custodial Parent' },
        { key: 2, value: 'Non-Custodial Parent' },
        { key: 3, value: 'Grandparent' },
        { key: 4, value: 'Guardian' },
        { key: 5, value: 'Emergency Contact' },
        { key: 6, value: 'Unspecified' }
      ]
    },
    states () {
      if (typeof window.states !== 'undefined') {
        const asObject = window.states
        const asArray = []
        for (const state in asObject) {
          asArray.push({ key: state, value: asObject[state] })
        }
        return asArray
      }
      return []
    },
    title () {
      if (isEmpty(this.id)) {
        return 'Add New Contact'
      } else {
        return 'Edit ' + this.contact.first_name + this.contact.last_name
      }
    },
    student_id () {
      return this.information_card.student_id
    },
    member_id () {
      return this.information_card.member_id
    },
    information_card () {
      return InformationCard.find(this.information_card_id)
    },
    information_card_contact () {
      if (!isEmpty(this.id) && this.id !== '') {
        const ic = InformationCardContact.query()
          .whereId(this.id)
          .orderBy('sort')
          .with('contact.*')
          .with('relationship')
          .first()
        return ic
      }
      return null
    }
  },
  validations: {
    contact: {
      relationship: {
        required
      },
      first_name: {
        required,
        maxLength: maxLength(252),
        minLength: minLength(2)
      },
      last_name: {
        required,
        maxLength: maxLength(252),
        minLength: minLength(2)
      },
      address: {
        maxLength: maxLength(252),
        minLength: minLength(2)
      },
      city: {
        maxLength: maxLength(252),
        minLength: minLength(2)
      },
      state: {
        maxLength: maxLength(32),
        minLength: minLength(2)
      },
      zip: {
        maxLength: maxLength(32),
        minLength: minLength(5)
      },
      email: {
        email: email(),
        maxLength: maxLength(32),
        minLength: minLength(5)
      },
      phone_1: {
        required,
        maxLength: maxLength(32),
        minLength: minLength(7)
      },
      phone_2: {
        required,
        maxLength: maxLength(32),
        minLength: minLength(7)
      }
    }
  },
  methods: {
    loadFromId () {
      if (!isEmpty(this.id) && this.id !== '') {
        /** @type { InformationCardContact } */
        const informationCardContact = this.information_card_contact
        const contact = this.contact
        contact.first_name = informationCardContact.contact.first_name
        contact.last_name = informationCardContact.contact.last_name
        contact.can_pickup = informationCardContact.can_pickup
        contact.is_custodial = informationCardContact.is_custodial
        contact.is_resident = informationCardContact.is_resident
        contact.relationship = {
          key: informationCardContact.relationship.id,
          value: informationCardContact.relationship.title
        }
        if (!isEmpty(informationCardContact.contact.addresses)) {
          const address = informationCardContact.contact.addresses[0]
          contact.address = address.address1
          contact.city = address.city
          contact.state = this.states.find(function (state) {
            return address.state === state.key
          })
          contact.zip = address.zip
        }
        if (!isEmpty(informationCardContact.contact.phones)) {
          if (informationCardContact.contact.phones.length >= 1) {
            const phone1 = informationCardContact.contact.phones[0]
            contact.phone_1 = phone1.phone_number
            contact.phone_1_type = this.phoneTypes.find(function (phone) {
              return phone1.type === phone.key
            })
          }
          if (informationCardContact.contact.phones.length >= 2) {
            const phone2 = informationCardContact.contact.phones[1]
            contact.phone_2 = phone2.phone_number
            contact.phone_2_type = this.phoneTypes.find(function (phone) {
              return phone2.type === phone.key
            })
          }
        }
        if (!isEmpty(informationCardContact.contact.emails)) {
          const email = informationCardContact.contact.emails[0]
          contact.email = email.address

          contact.email_type = this.emailTypes.find(function (checkEmail) {
            return email.type === checkEmail.key
          })
        }
      }
    },
    save (event) {
      if (!isEmpty(event)) {
        event.target.disabled = true
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
      }
      const vm = this
      const informationCardContact = this.information_card_contact_json

      this.$wait.start('contact.saving')
      API.post('/information_cards_contacts/save', informationCardContact)
        .then(response => {
          const data = response.data
          if (data.result.isError === false) {
            this.$notify({
              group: 'default',
              type: 'success',
              title: 'contact saved',
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
            InformationCardContact.insertOrUpdate({ data: data })
            vm.shouldClose(event)
            vm.$wait.end('contact.saving')
          } else {
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'information card error',
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })

            vm.$wait.end('contact.saving')
          }
        })
        .catch(e => {
          this.$notify({
            group: 'default',
            type: 'error',
            title: 'information card catch',
            text: e.message,
            duration: 2000,
            speed: 1000
          })

          vm.$wait.end('contact.saving')
        })
    },
    shouldClose (event) {
      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
      this.$emit('shouldClose', event)
    }
  }
}
</script>

<style scoped>
.table-row-move {
  transition: transform 5s;
}
</style>

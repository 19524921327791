<template>
  <div class="card pb-4">
    <div class="card-header">
      <h3>
        {{ information_card.name }}
        <small>for {{ schoolYear(information_card.school_year) }}</small>
      </h3>
      <h5>
        Created: {{ dateString(information_card.created) }} Signed:
        {{ dateString(information_card.signed) }}
      </h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          Gender: {{ information_card.gender === 'female' ? 'Female' : 'Male' }}
        </div>
        <div class="col-md-4">
          Date of Birth: {{ information_card.birthdayAsString }}
        </div>
        <div class="col-md-4">{{ enrollment_type }}</div>
      </div>
      <div class="row">
        <div class="col-md-4">Grade: {{ grade }}</div>
        <div class="col-md-4">School: {{ displayAbbreviation(school) }}</div>
        <div class="col-md-4">
          Teacher:
          <template v-if="teacher">
            {{ teacher.name }}
            {{
              teacher.grade ? teacher.grade + '/' : ''
            }}{{ teacher.school ? teacher.school : '' }}
          </template>
          <template v-else>
            Unset
          </template>
        </div>
      </div>
      <address-view
          :address="information_card.address"
          :city="information_card.city"
          :state="information_card.state"
          :zip="information_card.zip"
      />
      <div class="row">
        <div class="col col-md-6">
          <div class="row">
            <div class="col col-md-8">
              <h5>
                Parent/ Guardian
                <small v-if="['b', 'm'].includes(information_card.home)">
                  <span class="badge-info badge">lives with</span>
                </small>
              </h5>
              <strong>{{ information_card.mother_guardian_first }}
                {{ information_card.mother_guardian_last }}</strong>
            </div>
            <div class="col col-md-4">
              <div v-if="!isEmpty(information_card.mother_guardian_phone)">
                P: {{ phone(information_card.mother_guardian_phone) }}
              </div>
              <div v-if="!isEmpty(information_card.mother_guardian_cell)">
                C: {{ phone(information_card.mother_guardian_cell) }}
              </div>
            </div>
          </div>
          <div v-if="!isEmpty(information_card.mother_guardian_email)">
            Email: {{ information_card.mother_guardian_email }}
          </div>
          <address-view
              :address="information_card.mother_guardian_address"
              :city="information_card.mother_guardian_city"
              :state="information_card.mother_guardian_state"
              :zip="information_card.mother_guardian_zip"
          />
        </div>
        <div
            v-if="information_card.father_guardian_first !== ''"
            class="col col-md-6"
        >
          <div class="row">
            <div class="col col-md-6">
              <h5>
                Parent/ Guardian
                <small v-if="information_card.home === 'b' || information_card.home === 'm'">
                  <span class="badge-info badge">lives with</span>
                </small>
              </h5>
              <strong>{{ information_card.father_guardian_first }}
                {{ information_card.father_guardian_last }}</strong>
            </div>
            <div class="col col-md-6">
              <div v-if="!isEmpty(information_card.father_guardian_phone)">
                P: {{ phone(information_card.father_guardian_phone) }}
              </div>
              <div v-if="!isEmpty(information_card.father_guardian_cell)">
                C: {{ phone(information_card.father_guardian_cell) }}
              </div>
            </div>
          </div>
          <div v-if="!isEmpty(information_card.father_guardian_email)">
            Email: {{ information_card.father_guardian_email }}
          </div>
          <address-view
              :address="information_card.father_guardian_address"
              :city="information_card.father_guardian_city"
              :state="information_card.father_guardian_state"
              :zip="information_card.father_guardian_zip"
          />
        </div>
      </div>
      <div
          v-if="information_card.information_card_contacts.length > 0"
          class="row"
      >
        <div class="col">
          <h5>Emergency Contacts</h5>
          <p v-if="information_card.individual_called !== ''">
            Call First: {{ information_card.individual_called }}
          </p>
          <contacts
              :information_card="information_card"
              :member_id="information_card.member_id"
              :editable="false"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h5>Allergy/ Medical Information</h5>
          <medicals
              :information_card_id="information_card.id"
              :editable="false"
              :year="information_card.school_year"
              :medical_complete="information_card.medical_complete"
              :member_id="information_card.member_id"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <strong>Health Provider: </strong
          >{{ information_card.health_care_provider }}
          {{ phone(information_card.health_care_provider_phone) }}
        </div>
        <div class="col-md-4">
          <strong>Insurance: </strong>{{ information_card.insurance_carrier }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <strong>Not to remove: </strong>{{ information_card.not_to_remove }}
          {{ phone(information_card.health_care_provider_phone) }}
        </div>
        <div class="col-md-4">
          <strong>Custody Papers filed with district: </strong
          >{{ niceBoolean(information_card.custody_papers_filed) }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <strong>Has IEP: </strong>{{ niceBoolean(information_card.has_iep) }}
        </div>
        <div class="col-md-4">
          <strong>Has 504: </strong>{{ niceBoolean(information_card.has_504) }}
        </div>
      </div>
      <div class="row">
        <div v-if="special_instructions !== ''" class="col-md-6">
          <p><strong>Special Instructions</strong></p>
          <nl2br tag="p" :text="special_instructions"/>
        </div>
        <div v-else class="col-md-6">
          <strong>No Special Instructions</strong>
        </div>
        <div v-if="additional_comments !== ''" class="col-md-6">
          <p><strong>Additional Information</strong></p>
          <nl2br tag="p" :text="additional_comments"/>
        </div>
        <div v-else class="col-md-6">
          <strong>No Additional Comments</strong>/
        </div>
      </div>
      <div class="row">
        <div
            v-if="information_card.signatures.length > 0"
            class="col col-md-6 col-lg-4 mx-auto"
        >
          <signature-view :signature="information_card.signatures[0]"/>
        </div>
        <div v-else class="col col-md-10 col-lg-8 mx-auto">
          <div class="alert alert-warning">
            <p class="lead">There is no signature on this information card</p>
            <p>You will need to edit the card to add one at this time</p>
            <cs-button
                v-if="editable"
                @handle-click="shouldEdit($event)"
                iconLeft="edit"
                type="warning"
            >
              Edit
            </cs-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <cs-button
          v-if="editable"
          @handle-click="shouldEdit($event)"
          iconLeft="edit"
          type="warning"
      >
        Edit
      </cs-button>
      <cs-button
          @handle-click="shouldClose($event)"
          iconLeft="cancel"
          type="primary"
      >
        Close
      </cs-button>
    </div>
  </div>
</template>

<script>
import { displayAbbreviation, InitialState } from '@/utilities/cs-utilities'
import isEmpty from 'lodash/isEmpty'
import AddressView from './AddressView'
import Contacts from './Contacts'
import Medicals from './Medicals'
import SignatureView from './SignatureView'
import InformationCard from '@/models/InformationCard'
import Teacher from '@/models/Teacher'
import School from '@/models/School'
import Grade from '@/models/Grade'

import { dateString, schoolYear } from '@/utilities/dateFormatters'
import { niceBoolean, toList, phone } from '@/utilities/stringFormatters'
export default {
  name: 'InformationCardView',
  components: {
    AddressView: AddressView,
    Contacts: Contacts,
    Medicals: Medicals,
    SignatureView: SignatureView
  },
  data () {
    return {
      enrollment_types: [],
      states: []
    }
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  created () {
    const initialState = InitialState()
    if (typeof initialState.enrollment_types !== 'undefined') {
      this.enrollment_types = initialState.enrollment_types
    }
    if (typeof initialState.teachers !== 'undefined') {
      var teachers = initialState.teachers
      Teacher.insertOrUpdate({ data: teachers })
    }
    if (typeof initialState.schools !== 'undefined') {
      const schools = initialState.schools
      School.insertOrUpdate({ data: schools })
    }
    if (typeof window.states !== 'undefined') {
      const asObject = initialState.states
      const asArray = []
      for (const state in asObject) {
        if (Object.prototype.hasOwnProperty.call(asObject, state)) {
          asArray.push({ key: state, value: asObject[state] })
        }
      }
      this.states = asArray
    }
    if (typeof initialState.grades !== 'undefined') {
      var grades = initialState.grades
      Grade.insertOrUpdate({ data: grades })
    }
  },
  computed: {
    additional_comments () {
      if (!isEmpty(this.information_card.additional_comments)) {
        return this.information_card.additional_comments
      } else {
        return ''
      }
    },
    special_instructions () {
      if (!isEmpty(this.information_card.special_instructions)) {
        return this.information_card.special_instructions
      } else {
        return ''
      }
    },
    enrollment_type: function () {
      return this.enrollment_types[this.information_card.enrollment_type]
    },
    teacher () {
      if (this.information_card.teacher) {
        return this.information_card.teacher
      } else {
        return null
      }
    },
    grade () {
      if (
        typeof this.information_card.grade_object !== 'undefined' &&
          this.information_card.grade_object !== null
      ) {
        return this.information_card.grade_object.grade
      } else if (this.information_card.grade !== '') {
        return this.information_card.grade
      } else {
        return this.information_card.student.grade
      }
    },
    school () {
      if (
        typeof this.information_card.school_object !== 'undefined' &&
          this.information_card.school_object !== null
      ) {
        return this.information_card.school_object.name
      } else if (this.information_card.school !== '') {
        return this.information_card.school
      } else {
        return this.information_card.student.school
      }
    },
    information_card () {
      const ic = InformationCard.query()
        .whereId(this.id)
        .with('information_card_contacts.*')
        .with('information_card_medicals.*')
        .with('student.*')
        .with('teacher')
        .with('site')
        .with('signatures')
        .first()
      if (ic !== null) {
        ic.school_object = School.query()
          .where('abbreviation', ic.school)
          .first()
        ic.grade_object = Grade.query()
          .where('slug', ic.grade)
          .first()
        ic.signatures.sort(function (signature1, signature2) {
          return signature1.created < signature2.created ? 1 : -1
        })
      }

      return ic
    }
  },
  methods: {
    displayAbbreviation,
    isEmpty,
    niceBoolean,
    toList,
    dateString,
    schoolYear,
    phone,
    shouldClose (event) {
      this.$emit('cancel', event)
    },
    shouldEdit (event) {
      this.$emit('edit', event)
    }
  }
}
</script>

<style scoped></style>

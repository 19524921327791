<template>
  <div class="row">
    <cs-form-group
      class="col-md-6"
      label="Relationship to Student"
      input_id="relationship"
      :validator="$v.contactLink.relationship"
    >
      <template v-slot:input>
        <multiselect
          v-model="$v.contactLink.relationship.$model"
          :options="relationshipOptions"
          :multiple="false"
          placeholder="Type"
          track-by="value"
          label="value"
          name="test"
        >
          >
        </multiselect>
      </template>
      <template v-slot:invalid-feedback>
        <transition mode="out-in">
          <div class="error" v-if="!$v.contactLink.relationship.$invalid">
            Please select a relationship to your student
          </div>
        </transition>
      </template>
    </cs-form-group>
    <cs-form-group
      class="col-md-6"
      label="Permissions"
      input_id="relationship"
    >
      <template v-slot:input>
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            :id="'can-pickup-' + contact.id"
            v-model="contactLink.can_pickup"
          />
          <label class="custom-control-label" :for="'can-pickup-' + contact.id"
            >Can Pickup</label
          >
        </div>

        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            :id="'is-custodial' + contact.id"
            v-model="contactLink.is_custodial"
          />
          <label class="custom-control-label" :for="'is-custodial' + contact.id"
            >Is Custodial</label
          >
        </div>
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            :id="'is-resident' + contact.id"
            v-model="contactLink.is_resident"
          />
          <label class="custom-control-label" :for="'is-resident' + contact.id"
            >Is Resident</label
          >
        </div>
      </template>
    </cs-form-group>
    <div class="w-100 d-flex justify-content-around">
      <cs-button
        @handle-click.prevent="linkContact"
        iconLeft="link"
        type="primary"
      >
        Link to card
      </cs-button>
      <cs-button
        v-if="false"
        @handle-click.prevent="linkContactAndClose"
        iconLeft="link"
        iconRight="cancel"
        type="primary"
      >
        Link to card and close
      </cs-button>
    </div>
    <cs-loading v-wait:visible="'link.contact.' + this.contact.id">
      Linking...
    </cs-loading>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import InformationCard from '@/models/InformationCard'
import { API } from '@/utilities/http-common'
import InformationCardContact from '@/models/InformationCardContact'

export default {
  name: 'InformationCardContactLink',
  mixins: [validationMixin],
  props: {
    informationCardId: {
      type: [String, Number],
      required: true
    },
    contact: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      contactLink: {
        information_card_id: 0,
        relationship: { key: 5, value: 'Emergency Contact' },
        contact_id: 0,
        member_id: 0,
        student_id: 0,
        can_pickup: false,
        is_custodial: false,
        is_resident: false
      }
    }
  },
  mounted () {
    this.contactLink.information_card_id = this.informationCardId
    this.contactLink.contact_id = this.contact.id
    this.contactLink.member_id = this.information_card.member_id
    this.contactLink.student_id = this.information_card.student_id
  },
  computed: {
    member_id () {
      return this.information_card.member_id
    },
    information_card () {
      return InformationCard.find(this.informationCardId)
    },
    relationshipOptions () {
      return [
        { key: 1, value: 'Custodial Parent' },
        { key: 2, value: 'Non-Custodial Parent' },
        { key: 3, value: 'Grandparent' },
        { key: 4, value: 'Guardian' },
        { key: 5, value: 'Emergency Contact' },
        { key: 6, value: 'Unspecified' }
      ]
    }
  },
  validations: {
    contactLink: {
      relationship: {
        required
      }
    }
  },
  methods: {
    linkContact (event) {
      if (!isEmpty(event)) {
        // stop the propagation of the click
        event.preventDefault()
        event.target.disabled = true
      }
      this.link()
        .then(() => {
          if (!isEmpty(event)) {
            event.target.disabled = false
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e)
          if (!isEmpty(event)) {
            event.target.disabled = false
          }
        })
    },
    linkContactAndClose (event) {
      if (!isEmpty(event)) {
        // stop the propagation of the click
        event.preventDefault()
        event.target.disabled = true
      }
      this.link()
        .then(() => {
          if (!isEmpty(event)) {
            this.$parent.$emit('should-close', event)
            event.target.disabled = false
          }
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e)
          if (!isEmpty(event)) {
            event.target.disabled = false
          }
        })
    },
    link () {
      this.$wait.start('link.contact.' + this.contact.id)
      const vm = this
      return new Promise((resolve, reject) => {
        const params = this.contactLink
        params.student_id = this.information_card.student_id
        params.member_id = this.information_card.member_id
        params.relationship_id = params.relationship.key
        API.post('/information_cards_contacts/link.json', params)
          .then(response => {
            const data = response.data
            if (data.result.isError === false) {
              this.$notify({
                group: 'default',
                type: 'success',
                title: 'contact linked',
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })
              InformationCardContact.insertOrUpdate({ data: data })
              vm.$wait.end('link.contact.' + this.contact.id)
              resolve()
            } else {
              this.$notify({
                group: 'default',
                type: 'error',
                title: 'Linking contact error',
                text: data.result.message,
                duration: 2000,
                speed: 1000
              })

              vm.$wait.end('link.contact.' + this.contact.id)
              reject(response)
            }
          })
          .catch(e => {
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'Linking contact error',
              text: e.message,
              duration: 2000,
              speed: 1000
            })
            vm.$wait.end('link.contact.' + this.contact.id)
            reject(e)
          })
      })
    }
  }
}
</script>

<style scoped></style>

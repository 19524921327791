<template>
  <div class="card">
    <h3 class="card-header">
      {{ medical.the_condition }}
    </h3>
    <div class="card-body">
      <span v-if="medical.food" class="col-6">Food Allergy:</span>
      <span class="lead">
        {{ medicalType.name }}
      </span>
      <dl class="row">
        <dt class="col-sm-3">Description</dt>
        <dd class="col-sm-9">
          <nl2br tag="p" :text="medical.description" />
        </dd>

        <dt class="col-sm-3">Instructions</dt>
        <dd class="col-sm-9">
          <nl2br tag="p" :text="medical.instructions" />
        </dd>
        <dt class="col-sm-3">Medicine(s)</dt>
        <dd class="col-sm-9">
          <nl2br tag="p" :text="medical.medicines" />
        </dd>
      </dl>
    </div>
    <div class="table-footer">
      <div class="card-footer">
        <cs-button
          v-if="editable"
          @handle-click="shouldEdit($event)"
          iconLeft="edit"
          type="warning"
        >
          Edit
        </cs-button>
        <cs-button
          @handle-click="shouldClose($event)"
          iconLeft="cancel"
          type="primary"
        >
          Close
        </cs-button>
      </div>
    </div>
  </div>
</template>

<script>
import InformationCardMedical from '@/models/InformationCardMedical'
export default {
  name: 'MedicalView',
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    shouldClose (event) {
      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
      this.$emit('shouldClose', event)
    },
    shouldEdit (event) {
      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
      this.$emit('shouldEdit', event)
    }
  },
  computed: {
    information_card_medical () {
      if (this.id !== 0) {
        return InformationCardMedical.query()
          .whereId(this.id)
          .with('medical')
          .first()
      }
      return null
    },
    medical: function () {
      const ic = this.information_card_medical
      if (ic === null) {
        return null
      }
      return ic.medical
    },
    medicalType: function () {
      const ic = this.information_card_medical
      if (ic === null) {
        return null
      }
      return ic.information_cards_medical_type
    }
  }
}
</script>

<style scoped></style>

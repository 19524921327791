<template>
  <div class="medicals">
    <div>
      <div v-if="medicals.length === 0" class="alert alert-info">
        <h3>Medical or Allergy Information for {{ schoolYear(year) }}.</h3>
        <p class=" lead">
          Please ensure that any medical condtions, physical conditions, or
          allergies are listed that may impact your student in our programs.
        </p>
        <div class="row justify-content-around">
          <cs-button
            v-if="showNoMedicalsButton"
            @handle-click.prevent="$emit('no-medical')"
            type="primary"
            ><cs-icon icon="medical" /> No Medical Conditions or
            <cs-icon icon="allergy" /> Allergies
          </cs-button>
          <cs-button
            v-if="editable"
            @handle-click.prevent="addMedical()"
            type="warning"
            ><cs-icon icon="medical" /> Add Medical Conditions or
            <cs-icon icon="allergy" /> Allergies
          </cs-button>
        </div>
      </div>
      <div v-else class="table-responsive">
        <table class="table table-striped table-bordered table-hover">
          <thead class="thead-light">
            <tr>
              <th>Food</th>
              <th>Type</th>
              <th>Condition</th>
              <th v-if="editable" class="action">Actions</th>
              <th v-else>Additional Info</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="card_medical in medicals" :key="card_medical.id">
              <td>{{ card_medical.medical.food ? "Yes" : "No" }}</td>
              <td>{{ card_medical.medicalType }}</td>
              <td><span v-html="card_medical.medical.the_condition" /></td>
              <td v-if="editable">
                <cs-button
                  @handle-click.prevent="view(card_medical.id)"
                  iconLeft="view"
                  type="info"
                >
                  View
                </cs-button>
                <cs-button
                  @handle-click.prevent="edit(card_medical.id)"
                  iconLeft="edit"
                  type="warning"
                >
                  Edit
                </cs-button>
                <cs-button
                  @handle-click.prevent="unlink(card_medical.id)"
                  iconLeft="delete"
                  type="danger"
                >
                  Delete
                </cs-button>
              </td>
              <td v-else>
                <p v-if="card_medical.medical.instructions !== ''">
                  <strong>Instructions: </strong
                  ><nl2br
                    tag="span"
                    :text="card_medical.medical.instructions"
                  />
                </p>

                <p v-if="card_medical.medical.description !== ''">
                  <strong>Description: </strong
                  ><nl2br tag="span" :text="card_medical.medical.description" />
                </p>
                <p v-if="card_medical.medical.medicines !== ''">
                  <strong>Medicines: </strong
                  ><nl2br tag="span" :text="card_medical.medical.medicines" />
                </p>
              </td>
            </tr>
          </tbody>
          <tfoot v-if="editable">
            <cs-button @handle-click.prevent="addMedical()" type="warning"
              ><cs-icon icon="medical" /> Add Condition
            </cs-button>
          </tfoot>
        </table>
      </div>
      <modal name="edit-medical" height="auto" :scrollable="true" width="80%">
        <medical-edit
          :id="medical_id"
          :information_card_id="information_card_id"
          :year="year"
          @didSave.prevent="hide('edit-medical')"
          @shouldClose.prevent="hide('edit-medical')"
        />
      </modal>
      <modal name="view-medical" height="auto" :scrollable="true" width="80%">
        <medical-view
          :id="medical_id"
          :year="year"
          :editable="editable"
          :information_card_id="information_card_id"
          @shouldEdit.prevent="shouldEdit('view-medical')"
          @shouldClose.prevent="hide('view-medical')"
        />
      </modal>
    </div>
  </div>
</template>

<script>
import MedicalEdit from './MedicalEdit'
import MedicalView from './MedicalView'
import InformationCardMedical from '@/models/InformationCardMedical'
import { isEmpty } from 'lodash/core'
import { API } from '@/utilities/http-common'
import { schoolYear } from '@/utilities/dateFormatters'
export default {
  name: 'Medicals',
  components: { MedicalEdit, MedicalView },
  props: {
    information_card_id: {
      type: [String, Number],
      required: true
    },
    member_id: {
      type: [String, Number],
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    year: {
      type: [String, Number],
      required: true
    },
    medical_complete: {
      validator: prop => typeof prop === 'boolean' || prop === null,
      required: true
    }
  },
  data () {
    return {
      medical_id: ''
    }
  },
  watch: {
    medicalCount (newValue) {
      if (newValue > 0) {
        this.$emit('medical-count', { medicalCount: newValue })
      }
    }
  },
  computed: {
    medicalCount () {
      if (!isEmpty(this.medicals)) {
        return this.medicals.length
      } else {
        return 0
      }
    },
    medicals () {
      return InformationCardMedical.query()
        .where('information_card_id', this.information_card_id)
        .with('medical')
        .get()
    },
    showNoMedicalsButton () {
      if (!this.editable) {
        return false
      }
      if (this.medicals.length > 0) {
        return false
      }
      if (this.medical_complete === true) {
        return false
      }
      return true
    }
  },
  methods: {
    schoolYear,
    isEmpty (value) {
      return isEmpty(value)
    },
    addMedical () {
      this.show('edit-medical')
    },
    show (name) {
      this.$modal.show(name)
    },
    hide (name) {
      this.$modal.hide(name)
    },
    view (id) {
      this.medical_id = id
      this.show('view-medical')
    },
    edit (id) {
      this.medical_id = id
      this.show('edit-medical')
    },
    unlink (id) {
      const params = {
        id: id
      }
      API.delete('/information_cards_medicals/delete', { params: params })
        .then(response => {
          const data = response.data
          if (data.result.isError === false) {
            this.$notify({
              group: 'default',
              type: 'success',
              title: 'Removed',
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
            InformationCardMedical.delete(id)
          } else {
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'delete error',
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
          }
        })
        .catch(e => {
          this.$notify({
            group: 'default',
            type: 'error',
            title: 'delete catch',
            text: e.message,
            duration: 2000,
            speed: 1000
          })
        })
    },
    shouldEdit (name) {
      this.$modal.hide(name)
      this.edit(this.medical_id)
    }
  }
}
</script>

<style scoped></style>

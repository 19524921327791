<template>
  <form @submit.prevent="save($event)">
    <div class="card">
      <h2 v-if="id !== ''" class="card-header">Edit {{ medical.condition }}</h2>
      <h2 v-else class="card-header">
        Add new medical or allergy information for {{ schoolYear(year) }}
      </h2>
      <div class="card-body">
        <div class="row">
          <cs-form-group label="" class="col-md-6" input_id="food">
            <template v-slot:input>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="can-pickup"
                  v-model="medical.food"
                />
                <label class="custom-control-label" for="can-pickup"
                  >Food Allergy</label
                >
              </div>
            </template>
          </cs-form-group>
          <cs-form-group
            class="col-md-6"
            label="Select type that best matches the condition"
            input_id="relationship"
            :validator="$v.medical.type"
          >
            <template v-slot:input>
              <multiselect
                v-model="$v.medical.type.$model"
                :options="typeOptions"
                :multiple="false"
                placeholder="Select best fit"
                track-by="value"
                label="value"
                name="test"
              >
                >
              </multiselect>
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.medical.type.$invalid">
                  Please select the type of condition. (this is used for state
                  reporting for some programs)
                </div>
              </transition>
            </template>
          </cs-form-group>
        </div>
        <div class="row">
          <cs-form-group
            class="col-md-8"
            label="Condition"
            input_id="the_condition"
            :validator="$v.medical.the_condition"
          >
            <template v-slot:help>
              This should be a brief description of the condition or allergy ({{
                $v.medical.the_condition.$model.length
              }}
              of {{ $v.medical.the_condition.$params.maxLength.max }})
            </template>
            <template v-slot:input>
              <input
                type="text"
                class="form-control"
                id="the_condition"
                v-model.trim.lazy="$v.medical.the_condition.$model"
                :class="{
                  'is-valid': !$v.medical.the_condition.$invalid,
                  'is-invalid': $v.medical.the_condition.$error
                }"
              />
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.medical.the_condition.minLength">
                  The condition should have at least
                  {{ $v.medical.the_condition.$params.minLength.min }} letters.
                </div>
              </transition>
            </template>
          </cs-form-group>
        </div>
        <div class="row">
          <cs-form-group
            class="col"
            label="Description"
            input_id="description"
            :validator="$v.medical.description"
          >
            <template v-slot:input>
              <textarea
                type="textarea"
                class="form-control"
                id="description"
                v-model.trim.lazy="$v.medical.description.$model"
                :class="{
                  'is-valid': !$v.medical.description.$invalid,
                  'is-invalid': $v.medical.description.$error
                }"
              />
            </template>
            <template v-slot:help>
              Optional extra information, could include indicators or more
              narrative information that may be helpful to instructors, care
              givers or first responders. ({{
                $v.medical.description.$model.length
              }}
              of {{ $v.medical.description.$params.maxLength.max }})
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.medical.description.minLength">
                  First name must have at least
                  {{ $v.medical.description.$params.minLength.min }}
                  letters.
                </div>
              </transition>
            </template>
          </cs-form-group>
        </div>
        <div class="row">
          <cs-form-group
            class="col col-md-6"
            label="Instructions"
            input_id="instructions"
            :validator="$v.medical.instructions"
          >
            <template v-slot:input>
              <textarea
                type="textarea"
                class="form-control"
                id="instructions"
                v-model.trim.lazy="$v.medical.instructions.$model"
                :class="{
                  'is-valid': !$v.medical.instructions.$invalid,
                  'is-invalid': $v.medical.instructions.$error
                }"
              />
            </template>
            <template v-slot:help>
              Optional instructions that could be helpful for instructors, care
              givers or first responders. ({{
                $v.medical.instructions.$model.length
              }}
              of {{ $v.medical.instructions.$params.maxLength.max }})
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.medical.instructions.minLength">
                  First name must have at least
                  {{ $v.medical.instructions.$params.minLength.min }}
                  letters.
                </div>
              </transition>
            </template>
          </cs-form-group>
          <cs-form-group
            class="col cold-md-6"
            label="Medications"
            input_id="medicines"
            :validator="$v.medical.medicines"
          >
            <template v-slot:input>
              <textarea
                type="textarea"
                class="form-control"
                id="medicines"
                v-model.trim.lazy="$v.medical.medicines.$model"
                :class="{
                  'is-valid': !$v.medical.medicines.$invalid,
                  'is-invalid': $v.medical.medicines.$error
                }"
              />
            </template>
            <template v-slot:help>
              Optional information about medications both used for treatment as
              well as ongoing maintance that may be useful for instructors, care
              givers or first responders. ({{
                $v.medical.medicines.$model.length
              }}
              of {{ $v.medical.medicines.$params.maxLength.max }})
            </template>
            <template v-slot:invalid-feedback>
              <transition mode="out-in">
                <div class="error" v-if="!$v.medical.medicines.minLength">
                  Medications should have at least
                  {{ $v.medical.description.$params.minLength.min }}
                  letters.
                </div>
              </transition>
            </template>
          </cs-form-group>
        </div>
      </div>
      <div class="card-footer">
        <cs-button
          :disabled="$v.$invalid"
          @handle-click="save($event)"
          iconLeft="save"
          type="primary"
          class="mx-2 float-right"
          >Save
        </cs-button>
        <cs-button
          @handle-click="shouldClose"
          iconLeft="cancel"
          type="danger"
          class="float-right"
        >
          Close
        </cs-button>
        <div v-wait:visible="'medical.saving'">
          <cs-loading theme="success">Saving the medical progress</cs-loading>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { API } from '@/utilities/http-common'
import { isEmpty } from 'lodash/core'
import { schoolYear } from '@/utilities/dateFormatters'
import Medical from '@/models/Medical'
import InformationCardMedical from '@/models/InformationCardMedical'
import InformationCard from '@/models/InformationCard'
export default {
  name: 'MedicalEdit',
  mixins: [validationMixin],
  props: {
    instance: {
      type: Object,
      default: () => {}
    },
    id: {
      type: [Number, String],
      default: null
    },
    information_card_id: {
      type: [Number, String],
      required: true
    },
    year: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      medical: new Medical(this.instance),
      maxLength: 300
    }
  },
  created () {
    if (this.id !== '') {
      this.loadFromId()
    }
  },
  watch: {
    // whenever question changes, this function will run
    'medical.food': function (newFood) {
      if (isEmpty(this.medical.type) && newFood) {
        this.medical.type = { key: 1, value: 'Allergy' }
      }
    },
    id () {
      this.loadFromId()
    }
  },
  computed: {
    student_id () {
      return this.information_card.student_id
    },
    member_id () {
      return this.information_card.member_id
    },
    information_card () {
      return InformationCard.find(this.information_card_id)
    },
    typeOptions () {
      return Medical.types()
    },
    med () {
      return Medical.find(this.id)
    },
    information_card_medical () {
      if (this.id !== 0) {
        return InformationCardMedical.query()
          .whereId(this.id)
          .with('medical')
          .first()
      }
      return null
    }
  },
  validations () {
    return Medical.validations()
  },
  methods: {
    schoolYear,
    loadFromId () {
      const informationCardMedical = this.information_card_medical
      const medical = this.medical
      medical.the_condition = informationCardMedical.medical.the_condition
      medical.description = informationCardMedical.medical.description
      medical.instructions = informationCardMedical.medical.instructions
      medical.medicines = informationCardMedical.medical.medicines
      medical.type = {
        key: informationCardMedical.information_cards_medical_type.id,
        value: informationCardMedical.information_cards_medical_type.name
      }
      medical.food = informationCardMedical.medical.food
    },
    save (event) {
      if (!isEmpty(event)) {
        event.target.disabled = true
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
      }
      const vm = this
      const medical = this.medical.$toJson()
      medical.information_card_id = this.information_card_id
      medical.member_id = this.member_id
      medical.student_id = this.student_id
      if (!isEmpty(this.information_card_medical)) {
        medical.id = this.information_card_medical.medical_id
        medical.information_card_medical_id = this.information_card_medical.id
      }
      this.$wait.start('medical.saving')
      API.post('/information_cards_medicals/save', medical)
        .then(response => {
          const data = response.data
          if (data.result.isError === false) {
            this.$notify({
              group: 'default',
              type: 'success',
              title: 'medical/allergy saved',
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })
            InformationCardMedical.insertOrUpdate({ data: data })
            vm.shouldClose(event)
            vm.$wait.end('medical.saving')
          } else {
            this.$notify({
              group: 'default',
              type: 'error',
              title: 'information card error',
              text: data.result.message,
              duration: 2000,
              speed: 1000
            })

            vm.$wait.end('medical.saving')
          }
        })
        .catch(e => {
          this.$notify({
            group: 'default',
            type: 'error',
            title: 'information card catch',
            text: e.message,
            duration: 2000,
            speed: 1000
          })

          vm.$wait.end('medical.saving')
        })
    },
    shouldClose (event) {
      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
      this.$emit('shouldClose', event)
    }
  }
}
</script>

<style scoped></style>

<template>
  <div class="card border-info">
    <h3 class="card-header bg-info-light">
      {{ contact.first_name }} {{ contact.last_name }}
    </h3>
    <div class="list-group list-group-flush">
      <div v-if="phones.length > 0" class="list-group-item">
        <strong>Phones</strong>
        <ul class="list-unstyled">
          <li v-for="phoneItem in phones" :key="phoneItem.id">
            {{ phone(phoneItem.phone_number) }}
            <span class="badge badge-info">{{ phoneItem.type }}</span>
          </li>
        </ul>
      </div>
      <div v-if="emails.length > 0" class="list-group-item">
        <strong>Emails</strong>
        <ul class="list-unstyled">
          <li v-for="email in emails" :key="email.id">
            {{ email.address }}
            <span class="badge badge-info">{{ email.type }}</span>
          </li>
        </ul>
      </div>
      <div v-if="addresses.length > 0" class="list-group-item">
        <strong>Addresses</strong>
        <ul class="list-unstyled">
          <li v-for="address in addresses" :key="address.id">
            {{ address.address1 }} <br />{{ address.address1 }}
            {{ address.city }}, {{ address.state }} {{ address.zip }}
          </li>
        </ul>
      </div>
    </div>
    <div class="card-footer">
      <slot name="footer" :shouldClose="shouldClose"></slot>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import { phone } from '@/utilities/stringFormatters'

export default {
  name: 'ContactCard',
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      shouldClose: null
    }
  },
  created () {
    this.$on('should-close', function (event) {
      this.$parent.$emit('should-close', event)
    })
  },
  computed: {
    phones: function () {
      if (!isEmpty(this.contact.phones)) {
        return this.contact.phones
      } else {
        return []
      }
    },
    emails: function () {
      if (!isEmpty(this.contact.emails)) {
        return this.contact.emails
      } else {
        return []
      }
    },
    addresses: function () {
      if (!isEmpty(this.contact.emails)) {
        return this.contact.emails
      } else {
        return []
      }
    }
  },
  methods: {
    phone
  }
}
</script>

<style scoped></style>
